import { useRef, useContext, useEffect, useCallback, useState } from "react";
import styles from "./styles.module.css";
import { contextGlobal } from "App";

export let uiSliderSetVal;

export default (props) => {
  const global = useContext(contextGlobal);
  const refSlider = useRef(null);
  const refBar = useRef(null);
  const refList = useRef(null);
  const refSliderButton = useRef(null);
  const [stmIndex, setStmIndex] = useState(null);
  const [stmList, setStmList] = useState(props.list);
  uiSliderSetVal = setStmIndex;
  let flgSlideStart = false;
  let posStartX;

  //---useEffects
  useEffect(() => {
    handleSetVal(stmIndex);
  }, [stmIndex, stmList]);

  //---handles
  function handleSlideStart(e) {
    flgSlideStart = true;
    refSliderButton.current.classList.add(styles.active);
    let screenX = handleGetScreenX(e);
    let val = 0;
    if (refSliderButton.current.style.right) {
      val = parseInt(refSliderButton.current.style.right);
    }
    posStartX = screenX + val;
  }
  function handleSlideMove(e) {
    if (!flgSlideStart) return;
    let screenX = handleGetScreenX(e);
    let tmpX = posStartX - screenX;
    if (tmpX < 0) {
      tmpX = 0;
    }
    if (tmpX > refBar.current.clientWidth) {
      tmpX = refBar.current.clientWidth;
    }
    refSliderButton.current.style.right = tmpX + "px";
  }
  function handleSlideEnd(e) {
    flgSlideStart = false;
    refSliderButton.current.classList.remove(styles.active);
    handleCustomEvent();
  }

  function handleClickPoint(e) {
    let index = parseInt(e.target.getAttribute("data-num"));
    let tmpX = (refBar.current.clientWidth / (props.list.length - 1)) * index;
    refSliderButton.current.style.right = tmpX + "px";
    handleCustomEvent();
  }

  function handleGetScreenX(e) {
    if (e.changedTouches) {
      return e.changedTouches[0].screenX;
    } else {
      return e.screenX;
    }
  }

  function handleGetVal() {
    let tmpPos = refSliderButton.current.style.right;
    if (!tmpPos) {
      tmpPos = 0;
    }
    const val = parseInt(tmpPos) / refBar.current.clientWidth;
    return val;
  }

  function handleSetVal(val) {
    if (!props.list) return;
    let tmpPos = (refBar.current.clientWidth / (props.list.length - 1)) * val;
    if (tmpPos < 0 || !isFinite(tmpPos)) {
      tmpPos = 0;
    } else if (tmpPos > refBar.current.clientWidth) {
      tmpPos = refBar.current.clientWidth;
    }
    refSliderButton.current.style.right = tmpPos + "px";
  }

  function handleCustomEvent() {
    const val = handleGetVal();
    if (typeof props.onChange == "function") {
      props.onChange(val);
    }
  }

  return (
    <div
      ref={refSlider}
      className={`${styles.slider} ${global.landscape.val && styles.landscape}`}
      onMouseDown={handleSlideStart}
      onMouseLeave={handleSlideEnd}
      onMouseMove={handleSlideMove}
      onMouseUp={handleSlideEnd}
      onTouchStart={handleSlideStart}
      onTouchMove={handleSlideMove}
      onTouchEnd={handleSlideEnd}
    >
      <div className={styles.inner}>
        <ul ref={refList} className={styles.list}>
          {props.list &&
            props.list.map((data, idx) => (
              <li key={idx}>
                <span className="selectLan">
                  <span
                    data-num={idx}
                    className="ja"
                    onClick={handleClickPoint}
                  >
                    {data.ja}
                  </span>
                  <span
                    data-num={idx}
                    className="en"
                    onClick={handleClickPoint}
                  >
                    {data.en}
                  </span>
                  <span
                    data-num={idx}
                    className="ko"
                    onClick={handleClickPoint}
                  >
                    {data.ko}
                  </span>
                  <span
                    data-num={idx}
                    className="tw"
                    onClick={handleClickPoint}
                  >
                    {data.tw}
                  </span>
                  <span
                    data-num={idx}
                    className="cn"
                    onClick={handleClickPoint}
                  >
                    {data.cn}
                  </span>
                </span>
              </li>
            ))}
        </ul>
        <div ref={refBar} className={styles.bar}>
          <div ref={refSliderButton} className={styles.button}></div>
        </div>
      </div>
    </div>
  );
};
