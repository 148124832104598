import { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import imgIcon from "./images/vector_close.svg";

export default (props) => {
  const refButton = useRef(null);
  useEffect(() => {}, []);

  function handleClick() {
    if (props.onClick) props.onClick();
  }

  return (
    <a ref={refButton} onClick={handleClick} className={styles.button}>
      <div className={styles.inner}>
        <span className={styles.icon}>
          <img src={imgIcon} alt="×" />
        </span>
        <span className="selectLan">
          <span className="ja">閉じる</span>
          <span className="en">close</span>
          <span className="ko">닫기</span>
          <span className="tw">關閉</span>
          <span className="cn">关闭</span>
        </span>
      </div>
    </a>
  );
};
