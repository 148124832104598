import { useEffect, useState, useContext } from "react";
import styles from "./styles.module.css";
import { contextGlobal } from "App";
import Button_MapAll from "components/Button_MapAll";
import Button_GpsCenter from "components/Button_GpsCenter";

import MapControl, { gpsCentering } from "components/MapControl";
import PhotoSlide from "components/PhotoSlide";
import { modeList } from "datas";

export default () => {
  const global = useContext(contextGlobal);
  const [targetSpot, setTargetSpot] = useState(0);

  function handleGpsCenter() {
    gpsCentering();
  }
  function handleMapAll() {
    //マップ全体表示
    global.mode.set(modeList[2]);
  }

  return (
    <div
      className={`${styles.screen} ${
        global.mode.val != modeList[0] ? styles.active : null
      }`}
    >
      <div className={styles.btm}>
        <div className={styles.btmInner}>
          <div className={styles.btmInner2}>
            <Button_GpsCenter onClick={handleGpsCenter} />
            <Button_MapAll onClick={handleMapAll} />
          </div>
        </div>
      </div>
      <MapControl setTargetSpot={setTargetSpot} />
      <PhotoSlide targetSpot={targetSpot} />
    </div>
  );
};
