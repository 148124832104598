import { useEffect, useRef, useState, useContext } from "react";
import styles from "./styles.module.css";
import { camera, compass, contextGlobal } from "App";
import Button_Close from "components/Button_Close";
import UI_Slider, { uiSliderSetVal } from "components/UI_Slider";
import { modeList } from "datas";
import * as AFRAME from "aframe";
import {
  vertexShader1,
  fragmentShader1,
  fragmentShader2,
} from "assets/shaders";
import gsap from "gsap";
import imgIcon from "./images/icon_doc.svg";

export default () => {
  const global = useContext(contextGlobal);
  const refScene = useRef(null);
  const refContainer = useRef(null);
  const refCamera3D = useRef(null);
  const refVideo = useRef(null);
  const refPhotoName = useRef(null);
  const refListText = useRef(null);
  const refTextArea = useRef(null);
  const [stmNameList, setStmNameList] = useState(null);
  const [stmMeshs, setStmMeshs] = useState([]);
  const [stmViewTexts, setStmViewTexts] = useState([]);
  const [stmTargetTextIndex, setStmTargetTextIndex] = useState(null);
  const textureLoader = new AFRAME.THREE.TextureLoader();
  let flgInit = false;

  //---useEffects
  {
    useEffect(() => {
      handleInitScene();
    }, []);
    useEffect(() => {
      handleModeCheck();
    }, [global.mode.val]);
    useEffect(() => {
      handleInitArPhotos();
    }, [global.spotID.val]);
    useEffect(() => {
      handleLandscape();
    }, [global.landscape.val]);
  }

  //---handles
  function handleInitScene() {
    if (!refScene.current || flgInit) return;
    flgInit = true;
    //コンパス情報で北を軸にするイベント登録
    let rotationCache;
    let landscapeCache;
    compass.setCustomEvent(() => {
      if (!rotationCache || Math.abs(rotationCache - compass.degrees) > 20) {
        //北にコンテンツが向くように回転調整
        //前回から20度以上動かした場合だけ再計算（コンパス情報はフラフラしてるので）
        rotationCache = compass.degrees;
        refContainer.current.setAttribute("rotation", {
          y:
            compass.degrees +
            refCamera3D.current.getAttribute("rotation").y +
            360,
        });
      }
      //横向きにしたときも再計算
      if (landscapeCache != compass.flgLandscape) {
        rotationCache = null;
      }
      landscapeCache = compass.flgLandscape;
    });
  }
  function handleModeCheck() {
    if (global.mode.val != modeList[4]) {
      if (refContainer.current) {
        refContainer.current.setAttribute("visible", "false");
      }
      camera.stop();
      return;
    }
    if (refContainer.current) {
      refContainer.current.setAttribute("visible", "true");
    }
    if (refVideo.current) {
      camera.setVideo(refVideo.current).then(() => {
        camera.getUserMediaCamera();
      });
    }
  }

  function handleInitArPhotos() {
    // ARスポットかチェック
    if (!global.spotID.val || global.spots.val[global.spotID.val].type != "AR")
      return;
    // スライダー表示用の名前配列作成
    const nameList = global.spots.val[global.spotID.val].arDatas.map(
      (data) => data.name
    );
    setStmNameList(nameList); //UIスライダーのメモリ表示設定
    if (global.spots.val[global.spotID.val].arDefaultIndex) {
      uiSliderSetVal(global.spots.val[global.spotID.val].arDefaultIndex); //UIスライダーのメモリ位置初期化
      const defaultVal =
        (1 / global.spots.val[global.spotID.val].arDatas.length) *
        global.spots.val[global.spotID.val].arDefaultIndex;
      handleSliderChange(defaultVal);
    }
    // スライダーと連動するテキスト表示一覧
    const textList = global.spots.val[global.spotID.val].arDatas.map(
      (data) => data.text
    );
    setStmViewTexts(textList);
    // すでに写真がある場合は削除
    if (refContainer.current && refContainer.current.children.length) {
      [...refContainer.current.children].map((child) => {
        refContainer.current.removeChild(child);
      });
    }
    // 写真の枚数分 配置と初期化設定（カスタムシェーダー組込）
    const meshs = [];
    const tFade = textureLoader.load("images/tFade.png");
    global.spots.val[global.spotID.val].arDatas.map((data, idx) => {
      if (!data.photo) return;
      //aframeオブジェクトの削除処理が非同期なので再表示時エラーが出る、対策でIDにタイムスタンプを付加
      const id =
        global.spots.val[global.spotID.val].path +
        "-" +
        idx +
        "-" +
        new Date().getTime();
      const plane = document.createElement("a-plane");
      plane.setAttribute(id, "");
      plane.setAttribute("visible", "false");
      plane.setAttribute("width", data.photo.width);
      plane.setAttribute("height", data.photo.height);
      plane.setAttribute("position", data.photo.pos);
      plane.setAttribute("rotation", data.photo.rot);
      refContainer.current.appendChild(plane);
      AFRAME.registerComponent(id, {
        init: function () {
          //AFrameの3Dオブジェクトはinit後、meshにアクセス可能になる
          const mesh = this.el.getObject3D("mesh");
          meshs[idx] = mesh;
          setStmMeshs([...meshs]);
          let tPhoto = null;
          if (data.photo) {
            let tmpPath = "spotsData/";
            if (data.photo.src) {
              tmpPath +=
                global.spots.val[global.spotID.val].path + "/" + data.photo.src;
            } else {
              tmpPath += "frame.png";
            }
            tPhoto = textureLoader.load(tmpPath);
            plane.setAttribute("visible", "true");
          }
          let opacity = 0.0;
          if (idx == 1) opacity = 1.0;
          mesh.material = new AFRAME.THREE.ShaderMaterial({
            uniforms: {
              opacity: { value: opacity },
              fade: { value: 1.0 },
              tFade: { value: tFade },
              tPhoto: { value: tPhoto },
            },
            vertexShader: vertexShader1,
            fragmentShader: fragmentShader1,
          });
          mesh.material.transparent = true;
        },
        remove: function () {
          this.el.removeObject3D(id);
        },
      });
    });
  }

  function handleClose() {
    //Aframeは停止方法見当たらないため非表示で負荷軽減
    refContainer.current.setAttribute("visible", "false");
    camera.stop();
    global.mode.set(modeList[1]); //マップ画面へ戻る
  }

  function handleSliderChange(val) {
    console.log("val:", val);
    //スライダーのメモリ単位に写真のフェード値を変更
    if (global.spots.val[global.spotID.val]) {
      const separator =
        1 / (global.spots.val[global.spotID.val].arDatas.length - 1);
      const idxView = parseInt(val / separator); //表示写真インデックス計算
      const idxText = Math.round(val / separator); //表示テキストインデックス計算
      setStmTargetTextIndex(idxText);
      const nameObj = global.spots.val[global.spotID.val].arDatas[idxText].name;
      refPhotoName.current.innerHTML = `
      <span class="ja">${nameObj.ja}&nbsp;:&nbsp;詳細表示</span>
      <span class="en">${nameObj.en}&nbsp;:&nbsp;Detailed</span>
      <span class="ko">${nameObj.ko}&nbsp;:&nbsp;상세</span>
      <span class="tw">${nameObj.tw}&nbsp;:&nbsp;詳細</span>
      <span class="cn">${nameObj.cn}&nbsp;:&nbsp;详细</span>`;
      const opacity = val / separator - Math.floor(val / separator);
      if (stmMeshs.length) {
        stmMeshs.map((mesh, idx) => {
          if (!mesh || !mesh.material) return;
          let tmpVal = 0;
          if (idxView == idx) {
            tmpVal = 1 - opacity;
            console.log("idx:", tmpVal);
          }
          if (idxView + 1 == idx) {
            tmpVal = opacity;
            console.log("idx+1:", tmpVal);
          }
          gsap.to(mesh.material.uniforms.opacity, {
            value: tmpVal,
            duration: 1,
            ease: "sine.out",
            overwrite: true,
          });
        });
      }
    }
  }

  function handleLandscape() {
    //横向きの時は写真スケールを大きく調整
    if (refContainer.current && refContainer.current.children.length) {
      [...refContainer.current.children].map((child) => {
        if (global.landscape.val) {
          child.setAttribute("scale", "2.8 2.8 1");
        } else {
          child.setAttribute("scale", "1 1 1");
        }
      });
    }
  }

  function handleTextOpen() {
    refTextArea.current.classList.add(styles.active);
  }
  function handleTextClose() {
    refTextArea.current.classList.remove(styles.active);
  }

  return (
    <div
      className={`${styles.screen} ${
        global.mode.val == modeList[4] ? styles.active : ""
      }`}
    >
      <video ref={refVideo} className={styles.arVideo} playsInline></video>
      <div className={styles.btnArea}>
        <Button_Close onClick={handleClose} text="AR表示終了" />
      </div>
      <a-scene
        ref={refScene}
        main_scene
        xr-mode-ui="enabled: false"
        vr-mode-ui="enabled: false"
      >
        <a-entity ref={refContainer}></a-entity>
        <a-camera
          ref={refCamera3D}
          position="0 0 0"
          look-controls="touchEnabled:false"
          wasd-controls="enabled: false"
        ></a-camera>
      </a-scene>
      <div className={styles.btnText}>
        <span onClick={handleTextOpen}>
          <span className={styles.inner}>
            <img className={styles.icon} src={imgIcon} />
            <span ref={refPhotoName} className="selectLan"></span>
          </span>
        </span>
      </div>
      <div ref={refTextArea} className={styles.textArea}>
        <div className={styles.inner}>
          <div onClick={handleTextClose} className={styles.close}>
            <span></span>
          </div>
          <ul ref={refListText}>
            {stmViewTexts.length &&
              stmViewTexts.map((text, idx) => (
                <li
                  key={idx}
                  className={stmTargetTextIndex == idx ? styles.active : ""}
                >
                  <div className="scroll">
                    <div className="selectLan">
                      <p className="ja">{text.ja}</p>
                      <p className="en">{text.en}</p>
                      <p className="ko">{text.ko}</p>
                      <p className="tw">{text.tw}</p>
                      <p className="cn">{text.cn}</p>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <UI_Slider onChange={handleSliderChange} list={stmNameList} />
    </div>
  );
};
