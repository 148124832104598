import styles from "./styles.module.css";

export default (props) => {
  return (
    <svg
      className={`${styles.svg} ${props.size2 && styles.size2}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="1032px"
      height="837px"
      viewBox="0.5 0.5 1032 837"
    >
      <rect
        x="0.703"
        y="0.5"
        fill="#C6DBE8"
        width="1031.538"
        height="836.751"
      />
      <path
        className={styles.shadow2}
        fill="#F4F4F5"
        d="M0.703,0.5v836.751h245l-72-197.751l10.833-3.666l-8.5-25.5l-76,0.167l5.667-21.833l37,0.166l-17.667-49.666
              l6-43l12.042-4.253l14.323,41.624l7.229-2.659l-14.579-41.428l35.054-12.38l15.728,44.608l8-3l-15.934-44.36l31.883-11.261
              l14.531,41.292l8-3l-14.668-41.069l32.891-11.615L310.203,572.5l130,194l72.5-50.5l-91-136l130.5-18.5V556l15.5-1.833v-4.334
              l-16.334,2l-8.5-184.5l6.334-0.333l-1.334-46.667l-3.666-0.167l-4.334-21.333l1.5-29l-3.166-71.083h16l0.25,30.5l7-0.75v-29.75
              l48.25-2v32.5h6.75v-33l16.25-0.5v29.5h10.75V195l20.25-0.75v31.5l5.5-0.5v-31.5l45.5-1.75v30.25l4.75,0.25v-30.75h30.75v71.75
              l-12.25,1.5l0.5,4.167l-6.168,0.25l-1.582-30.667h-4.418l2.418,31.417l-22.334,2.5l-0.084-3.25l-5.916,0.333l-2.334-21.583
              l-3.082,0.167l2,21.667l-10.75,0.5v3.5l-89,6l1,14l89-6l18.5,152.5l-82,9.5l0.334,4l88-10.166l35.998,274.332
              c0,0-0.707,7.209,10.293,5.209s65.875-8.75,65.875-8.75s6.625,0,5.25-9s-35.375-265.75-35.375-265.75l107.125-14.125l35.25,267.5
              c0,0,1.5,6,8.25,5.25s67.5-8.25,67.5-8.25s6.25,1.25,4.75-9.5s-35-263-35-263l50.287-6.25V0.5H0.703z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="368.818,400 445.318,391.25
              445.318,395.625 433.068,397.25 446.818,405.75 448.068,424.75 437.568,438 438.068,443.75 425.068,443.75 411.568,458 398.068,448
              375.068,450.25 375.068,446.25 361.568,436.5 359.818,416.75 368.568,405.75 "
      />
      <path
        fill="#E8F3E8"
        stroke="#D1E7D4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M462.5,486.5l37,30.334
              c0,0-7,8.334-7.667,16s3,18.666,3,18.666l-9.333,2.667c0,0-2.667,0.333-6-1.667s-42.667-29.333-42.667-29.333s1.833-2.823,0-7
              L462.5,486.5z"
      />
      <path
        fill="#E8F3E8"
        stroke="#D1E7D4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M503.25,509.5l-34-28.75l7-8.75l55.5-7.25
              l4,30.5c0,0,1.25,3.25,0,4.25c0,0-8-0.5-16.5,1.25S503.25,509.5,503.25,509.5z"
      />
      <path
        fill="#E3F0F1"
        stroke="#C5DDE7"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M525,403.625l-7.25,7v3.75L512.5,420
              c0,0-12.625-7.625-11-17.625s11.48-10.25,11.678-10.25L525,403.625z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        points="385.167,656.834 443.5,741.834 487.5,711.168 430.167,626.168 "
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M335,362.5l-7.5,1.5l2.125,22.833
              l8.875-0.333l0.25,5.917l7.208-0.625l0.167,2.833l28.042-3.375l-0.354-4.25l7.688-1.25l-2.375-22.813l36.688-4.063l1.021,9.458
              c0,0,1.167,5.5,6.667,4.833l0.187,4.493l4.313-0.154l0.167,1.994l14.312-1.313l-0.312-5.521l1.833-0.208l-1.708-12.958l-3.125,0.333
              l-0.667-4.5h0.833l-0.833-4.396h1.5l-0.688-4.438l16.438-2.25l-1.188-10.813h-3.688l-0.313-3.375l18.875-2.438l-1.604-19.125
              L441,311.833l1,10.5l-2.5,0.5l-0.167-2.167L337.5,333.833l1.667,15L334,349L335,362.5z"
      />
      <polygon
        fill="#E8F3E8"
        stroke="#D1E7D4"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="466,351 469.438,377.167
              493.833,374.333 490.083,348.167 "
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M474.667,384.583l-0.75,6.667
              c0,0,4.117,1.679,9.25,0.875c5.583-0.875,8.75-3.737,8.75-3.737l-3.5-7.138c0,0-0.917,1.25-4.167,1.75l-0.03,1.372
              C484.22,384.372,479.5,385.833,474.667,384.583z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="499.5,307.833 517.125,307.167
              516.472,278.667 499.5,279.5 "
      />
      <rect
        x="523.875"
        y="259.375"
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        width="7.125"
        height="20.875"
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M506.25,253.75l9.625-8.875l0.597,31.875
              l-18.847,0.75v-6.375c0,0,3.031-4.406,8.656-4.531L506.25,253.75z"
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M501.625,251.375c0,0,1,8.25,3.375,13
              h-4.125c0,0-3.75-4.125-3.25-12.375C497.625,252,499.125,250.125,501.625,251.375z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="430,227.75 428.75,252.75
              469.438,254.25 471.25,229.25 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="415.5,527 418.25,539.5
              428.5,538 426.5,525.25 "
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M380,503.25l7.25,9.25l5.75-4.625
              c0,0,1.257-1.986-1.493-5.986s-5.495-2.973-5.495-2.973L380,503.25z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="522.167,467.949 522.875,472.865
              530.531,471.74 529.917,466.865 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        points="403.094,592.875 415.5,608.25 407.125,614.375 394.75,598.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        points="394.75,580.625 401.625,590.5 395.5,594.75 388.375,584.875 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        points="349.25,586.125 341.875,590.75 365.375,624.25 373.375,619 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="281.5,217.5 285.75,235.5
              308,230 303.914,212.212 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="330.75,232 333.75,246.292
              325,248.25 325.792,252.667 297.25,257.66 294.75,243.5 300.75,242.25 300.25,237.75 "
      />
      <circle
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        cx="297"
        cy="447.334"
        r="10.833"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="252.5,358.25 266.75,353.25
              292.25,424.625 278,430.25 "
      />
      <polygon
        fill="#E3F0F1"
        stroke="#C5DDE7"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="447.755,356.063 455.438,355.25
              457.438,373.938 449.688,374.563 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="201.5,434.833 208.833,454.833
              139.833,479.5 138.521,476.188 147.5,472.833 141.167,455.833 "
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M115.75,479.5l20.5-6.75l-1.5-4.5l1.5-1
              L117.5,413.5l-13.25,5.375L98.75,440C98.75,440,102,464.25,115.75,479.5z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="63.167,476.834 94.833,484.167
              92.689,492.133 98.633,493.448 96.625,504 103.167,505.648 92.167,547.501 54.623,539.304 56,532.75 53.215,532.451 54.125,526.875
              51.167,525.875 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="95,552.625 91,570.25
              75.125,566.875 79.625,549.375 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="138.104,786.227 167.5,775.167
              174.5,795.375 178,794.125 186.833,819.167 167.167,826.501 165.833,823.167 153.375,827.125 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="86.625,620.75 142.25,620.75
              193.5,763.375 136.125,784.625 131.625,771.875 144.875,767.125 140.813,755.125 129.375,759.5 125,749 121,750.375 116,734.875
              123.375,732.125 120.75,723 113.75,725.625 108.875,712 115.75,709.875 111.25,695.875 104.375,698.125 95.125,673.125
              98.125,671.75 88.125,645.25 93.125,644 91.875,640.5 87.125,641.375 82.125,627.75 88.125,627.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="426.5,183.833 427.625,189.375
              452.791,184.667 453.5,188.667 480.5,184.375 479.27,173.527 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="481.5,173.527 482.5,181.097
              492,179 490.625,171.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="488.25,181.097 492,180.375
              493.375,187.375 489.5,188.188 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="497.375,169.75 497.375,176.813
              509.563,176.813 509.563,168.375 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="495.5,178.938 495.5,196.375
              510.563,196.375 510.195,178.754 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="782,680.5 771.25,681.75
              769.688,667.188 763.375,667.813 759,634.75 757.313,634.906 756.75,628.5 797.5,623.5 803.25,666 780.945,669.118 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="756,586.501 756.813,592.438
              793,587.334 792.128,581.52 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="781,498.25 760.5,500.25
              761.375,513.625 758.219,513.751 765.25,571.125 790.625,568.125 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="770.666,454.167 774.422,483.667
              779.5,482.667 775.75,453.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="722.5,454.959 723.125,462
              726.75,461.375 726.145,454.469 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="700.5,304.667 702,322.5
              711.084,321.75 711.084,323.25 725.916,321.75 725.916,319.333 729.584,319 728.084,299.5 708.75,301.167 708.834,303.583 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="730.167,332.917 735.5,332.333
              739.5,366.167 734.25,367 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="564.875,161.875 564.875,171.75
              579.25,171.5 579.25,161.438 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="908.334,433.5 927.334,430.667
              931.334,460.5 912,463.334 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="913.834,475.667 932.834,473.167
              937.5,504.334 918,507.25 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="977.125,434.875 989.167,527.834
              961.667,531.334 949.167,438.501 "
      />
      <path
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M801.75,373.25l-22.25,1.5
              c0,0-15,7.75-12.5,27S780.969,423,784.234,423s9.766-2.75,10.266-5.25l14-1l1,10.75l32-3.75L830.75,364l-23.5,3l2,19.25l-5.875,0.75
              L801.75,373.25z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="846.167,360.542 852.5,406.833
              930.167,395.75 924.167,349.667 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="790.834,290 771,301.333
              794.166,342.167 814,331.167 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="819.334,327 827.5,340.167
              844.5,339.333 863.667,328.5 856,313.833 841.5,315.333 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="805.167,283.333 807,306.167
              859,300.667 856.75,278 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="876.75,292.083 899.25,330
              917.75,318.75 895,281 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="806.625,229.625 807.5,238.25
              844.125,235.25 843.625,226.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="812.688,241.375 813.5,255.167
              818.563,254.875 818.786,258.115 840.625,256.813 840.313,253.563 843.688,253.188 843,239.25 838.188,239.563 838.063,237.125
              816.418,238.897 816.563,241.063 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="937,310.75 938.5,323.75
              964.5,321 964.807,325.215 971.084,324.5 970.75,315 980.25,313.833 986,310.75 987.25,319.75 1002.25,318 1002.25,314.75
              1014.5,313.833 1012.5,294.25 1001,296.5 1002,302.25 997.5,304 997.25,299.25 968.75,304 969.25,307.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="991.5,359 995,387.75
              1024.25,383.5 1020.5,355 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="948.125,351.625 943.25,358.75
              941,358.75 939.5,348.25 947.375,346.875 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="951.5,353.875 945.625,366
              955,370.125 960.625,358.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="953.125,351.375 961.75,356.375
              969.125,344.25 957,345.75 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="944.375,368.5 942.75,372.875
              945.625,395.75 950.75,395.75 950.75,390 955.875,373.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="958,290.5 952.207,291.419
              951.564,285.792 936.16,287.396 935.75,284.25 931.428,284.629 930.167,273 936.459,272.292 937,276.155 948.563,274.688
              947.969,268.531 955.875,267.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="969.125,282.667 969.834,290.167
              976.917,289.667 976.292,282 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="777.5,166.167 777.5,188.5
              811.5,189.833 811.5,167.833 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="815.834,168.833 815.834,178
              819.5,178 819.5,191.5 834.834,192.333 834.834,168.833 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="854.834,187.167 848,187.167
              848,177 856.834,177 856.834,172.5 887.834,172.5 887.834,190.333 854.813,190.333 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="859.75,166.75 859.75,170.75
              932.125,173 932.125,168.833 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="614.334,168.833 615.167,184
              617.5,184 617.5,185.833 715.834,181.417 715,164.333 666.626,166.155 666.5,163.833 631.834,165.227 631.834,167.833 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="69.333,379 64.667,389.125
              73,393.167 77.167,382.833 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="67.333,391.5 84.5,400.667
              67.333,430 50.167,421.167 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="52.167,425.334 59.833,428.667
              53.833,444.5 46.167,441.834 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="43.75,442.25 37.75,463.5
              51.167,468.5 55.25,462.25 75.125,468.5 79.25,455.375 67.333,436.5 58.5,447.334 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="95,329.5 77.167,355.25
              70.917,367.833 95,381.25 117.5,345.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="125.167,303 143.667,321.5
              120.5,342.833 103.167,329.5 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="134.625,293.125 153.125,314.25
              181.25,288 162.25,267.625 "
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="167,268.625 183.292,286.167
              222.625,253.875 205.875,235.5 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="508.112,124.96 510.563,141.75
              429.75,153.25 423.5,127.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="414.25,129.25 421.5,154.75 395,160
              386,134 403.5,129.25 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="378.25,136.167 386,161.438
              345.25,166.594 339.5,146 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="331,149.5 336.833,167.833
              320.833,172.333 284.5,177.5 278.167,161.5 308.833,150.667 314.25,154.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="261.5,145.75 275.75,177.5
              272.25,178.754 273.25,181.451 239.25,189.375 226.25,159.25 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="105.75,264.25 155.75,223.856
              161,230.25 110.75,270.5 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="149.25,197.75 170,187.5
              183.5,213.75 167,223.856 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="505,82.668 507.049,116.5 421.5,120
              415.5,93.5 453.5,86 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="415.5,120 408,93.5 377.5,104.625
              384,125 402.5,120 "
      />
      <path
        fill="#E8F3E8"
        stroke="#D1E7D4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M533,165.227l3.25,28.023h76l-1.25-35.5
              C611,157.75,545.5,159.454,533,165.227z"
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="369.5,108.209 375,129.25
              336.833,139 331,120 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="322.75,123.604 328.25,142
              314.25,145.75 311,142 274.25,153.25 269.25,142 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="141.25,201.75 151,216.75
              99.25,256.5 86,239.25 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="79.5,244.83 103.167,275.5
              80.75,300 53,275.75 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.75,297.5l29.917,25.417L44,358.5
              L8.25,342.833C8.25,342.833,21.5,308.75,34.75,297.5z"
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="715.834,1.833 713.166,66.167
              755.166,68.167 757.875,1.833 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="661.5,1.833 658.5,63.5 702.834,66
              702.834,1.833 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="650.834,1.833 647.5,59.167
              644.166,62.833 604.5,61.167 606.834,1.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="368.833,82.668 375,97.167
              407.5,88.167 402.5,69.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="360.833,55.5 366.833,75
              399.5,62.667 396.5,43 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="353,57.5 359,76.5 321,91
              314.167,71.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="323.667,99.5 328.25,113.5
              366.167,99.5 360.833,84.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="315.5,102.333 320,116
              265.833,135.167 261,120.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="306.25,75 312.917,94.167
              257.333,112.75 251.417,96.417 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M252.167,121.5l5.5,16.927
              c0,0-41.833,14.24-69.333,31.406l-8.167-15.083C180.167,154.75,229.5,128.5,252.167,121.5z"
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M244,99.167l4.75,14.333l-73.25,33.833
              l-9.125-18.083C166.375,129.25,230.5,102.584,244,99.167z"
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="154.75,136.167 166.375,156.25
              133.375,178.5 122,161.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="170.5,163.5 176.25,174.5
              142.5,191.75 138.25,184.5 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="131.5,189.375 134.958,194.75
              81.75,233.375 78.083,229.125 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54.75,212.212L74,238.25l-33.75,38
              l-27-19.75c0,0,15.5-26.575,42.5-44.288"
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="60.833,207.167 72.5,221.5
              125.833,182.833 114.833,165.227 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.703,211.375L34.5,182.833l13,18.917
              c0,0-33,25.083-46.333,48.75"
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="40.167,177.625 53,196.833
              106.521,154.75 97.833,139 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="104.667,134.333 114.5,149.833
              148.167,125.667 139.833,110.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="147.333,97 159.333,118.75
              302.5,62.667 293,30.833 232.167,47.5 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="293.25,1.833 310.5,59
              402.5,26.833 499,10.75 499,0.5 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="285.5,1.25 290.5,23.25
              228.75,40.25 144.188,89 116,34.25 167.75,1.25 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="110.25,51.5 134.958,99.167
              97.5,123.25 80.75,99.167 78.083,100.5 65.25,80 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="1.75,124.75 33,166.594 90,128
              74,102.333 69,99.167 57.75,83.25 43.625,92.5 29.75,80 1.75,110.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="93.333,18 106.521,44 95.75,51.5
              81.5,29.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="75.25,34.75 88.5,55 60.833,73
              50.5,59 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="44.5,65.75 52.25,76.5 44.5,82.25
              35.75,74.792 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="203.25,171.5 218.917,163.5
              229.5,193.363 218.917,196.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="188,182.833 192.25,182.833
              203.25,200.5 203.25,204.333 200.75,205.678 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="403.75,40.5 414,86.25 455.333,78
              503.469,75 500.25,23.5 497.375,23.5 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="511.75,1.833 516.472,64
              592.5,62.833 595.75,0.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="517.125,78 520.834,126.833
              523.875,139.105 588.834,133.75 591.834,74.25 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="777.5,0.75 773.934,69.007
              818.166,70.167 821.834,0.75 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="830.5,0.75 828.188,71.5
              867.917,74.25 873.166,0.75 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="599.5,74.25 595.75,133.75
              645,131.833 647.167,76.125 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="655.5,76.125 653.167,132
              698.834,133.75 702.834,79.5 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="710.5,80.167 707.834,133.75
              752.167,135.5 754.834,81.833 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="825.334,83.833 773.666,82.5
              770.834,137.167 825.334,139.105 "
      />
      <polygon
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="834.834,84.833 832.166,140
              947.5,144.5 949.166,107.833 921.834,91 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1032.24,52.167L932.125,57.5v23.667
              L958,98.333l39.166-2.5c0,0,9.001,3.208,0.668,9.104L958,106.678l-2.25,37.822h54.75l21.74-5.395"
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="885,0.75 881,74.25 917.75,76.125
              921,1.833 "
      />
      <polyline
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="932.125,1.833 930.167,49
              1032.24,42.5 "
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.703,620.75L46.167,607
              c0,0-15.262-29.25-45.464-57.625"
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5,632.5L50.167,618l11.583,31.25v11.5
              l37.5,102.75c0,0-85.345,13.584-98.547,11.667"
      />
      <path
        fill="none"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1,789.25c0,0,64-7,79.25-9.25
              s30.75-4.647,30.75-4.647s4-1.853,4.75-4.603H125l23.75,65.5"
      />
      <path
        className={styles.shadow}
        fill="#E9E8E8"
        d="M1.75,507.25v-19.5c0-2.083,0.004-12.837,0-12.833c2.514-2.625,4.646-8.005,22.868-54.86
              c6.75-17.357,40.914-87.171,54-106.401c10.816-15.894,23.631-30.241,60.31-60.485c39.716-32.749,83.449-53.286,129.982-61.041
              c12.395-2.067,129.966,0.965,307.918,5.827C671.787,200.55,761.48,203,790.472,203c69.389,0,240.331-37.396,242.028-37.773
              l-0.26,19.053c-7.013,1.561-170.296,37.72-241.769,37.72c-29.25,0-119.069-2.454-214.163-5.051
              c-130.107-3.554-292.025-7.978-304.284-6.077c-43.188,7.198-83.904,26.362-121.009,56.958
              c-34.919,28.793-46.864,42.076-56.69,56.515c-11.529,16.941-44.924,84.402-52,102.599C15.12,496.902,5.544,505.207,1.75,507.25z"
      />
      <polygon
        fill="#F0EFEF"
        stroke="#E3E2E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="244.25,378 248.5,390
              236.25,394.625 238.781,402.156 236.203,403.094 236.781,404.469 226.875,408.063 226.125,406.438 211.313,411.938 211.75,413.125
              204.75,415.688 198.938,402.438 197.188,403.375 189.125,384.375 191.75,383.188 190.188,378.75 198.688,375.313 196.313,368.313
              212.75,362.563 215.563,369.563 227.563,366 228.313,368.625 232.813,366.938 237.313,379.875 "
      />
      <path
        className={styles.shadow}
        fill="#E9E8E8"
        d="M1.75,535.25v-28c2.729-3.22,59.04-72.892,70.409-84.93c11.652-12.337,109.719-97.828,144.049-121.859
              c34.893-24.425,78.784-31.995,108.764-35.564c31.561-3.756,153.55,9.857,154.762,9.996c17.655,2.037,110.901,5.895,129.178,4.993
              c37.984-1.886,93.383-5.038,99.53-6.096c2.438-0.639,9.063-1.562,39.296-5.562c35.23-4.662,88.471-11.707,108.462-15.872
              c30.854-6.428,174.886-65.953,176.3-66.523l-0.26,21.034c-5.836,2.354-139.956,56.358-172.369,63.111
              c-20.643,4.3-72.157,11.117-109.771,16.094c-17.853,2.362-34.715,4.594-37.1,5.125c0.246-0.055,0.426-0.117,0.549-0.158
              c-4.875,1.625-59.107,4.608-103.744,6.825c-19.376,0.961-113.536-2.943-132.134-5.088c-23.638-2.728-124.73-13.082-150.571-10.004
              c-16.162,1.924-65.346,7.779-100.569,32.436C193.179,338.553,95.689,423.621,85.245,434.68
              C74.206,446.368,2.415,534.466,1.75,535.25z M708.355,273.813c-0.211,0.051-0.376,0.108-0.499,0.149
              C708.004,273.913,708.167,273.864,708.355,273.813z"
      />
    </svg>
  );
};
