/**
 * 言語判定管理クラス
 */
export default class Language {
  code; // 国コード
  isEnable; // 言語判定済
  isJP; // 日本判定
  isEN; // 英語圏判定
  isKO; // 韓国判定
  isTW; // 繁体判定
  isCN; // 簡体判定

  constructor() {
    this.check();
  }
  /**
   * @description 言語設定を取得
   */
  check() {
    if (!window.navigator.language) {
      this.isEnable = false;
      return;
    }
    let code = window.navigator.language;
    const tmpLan = code.split("-")[0];
    if (tmpLan === "en") {
      code = tmpLan; //英語圏は国コードが多いのでまとめる
    }
    switch (code) {
      // 日本
      case "ja":
      case "ja-JP":
        this.code = "ja";
        this.isEN = true;
        break;
      // 英語
      case "en":
        this.code = "en";
        this.isEN = true;
        break;
      // 韓国
      case "ko":
      case "ko-KR":
        this.code = "ko";
        this.isKO = true;
        break;
      // 繁体判定
      case "zh-Hant": // 繁体字
      case "zh-TW": // 台湾
      case "zh-HK": // 香港
      case "zh-MO": // マカオ
        this.code = "tw";
        this.isTW = true;
        break;
      // 簡体判定
      case "zh-Hans": // 簡体字
      case "zh-CN": // 中国
      case "zh-SG": // シンガポール
      case "zh":
        this.code = "cn";
        this.isCN = true;
        break;
      default:
        this.code = code;
        break;
    }
    this.isEnable = true;
  }
}
