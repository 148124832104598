import { useRef, useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { contextGlobal } from "App";

export let changeUIZoomPlus;
export let changeUIZoomMinus;

export default (props) => {
  const global = useContext(contextGlobal);
  const refPlus = useRef(null);
  const refMinus = useRef(null);
  const [stmViewPlus, setStmViewPlus] = useState(true);
  const [stmViewMinus, setStmViewMinus] = useState(true);

  changeUIZoomPlus = handleChangePlus;
  changeUIZoomMinus = handleChangeMinus;

  useEffect(() => {}, []);

  //---handles
  function handlePlus(e) {
    if (typeof props.onClickPlus === "function") props.onClickPlus();
  }
  function handleMinus(e) {
    if (typeof props.onClickMinus === "function") props.onClickMinus();
  }
  function handleChangePlus(flg) {
    if (typeof flg === "boolean") {
      setStmViewPlus(flg);
    }
  }
  function handleChangeMinus(flg) {
    if (typeof flg === "boolean") {
      setStmViewMinus(flg);
    }
  }

  return (
    <div className={styles.zoom}>
      <div className={styles.inner}>
        <div
          ref={refPlus}
          className={`${styles.plus} ${stmViewPlus && styles.active}`}
          onClick={handlePlus}
        ></div>
        <div className={styles.split}></div>
        <div
          ref={refMinus}
          className={`${styles.minus} ${stmViewMinus && styles.active}`}
          onClick={handleMinus}
        ></div>
      </div>
    </div>
  );
};
