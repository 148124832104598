import { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { gps, compass, contextGlobal } from "App";
import imgGPS from "./images/vector_gps_direction.svg";
import SVG_Map from "components/SVG_Map";
import SVG_MapPin from "components/SVG_MapPin";
import Button_ar from "./Button_ar";
import Button_photo from "./Button_photo";
import UI_Zoom, {
  changeUIZoomPlus,
  changeUIZoomMinus,
} from "components/UI_Zoom";
import { guideMsgUpdate } from "components/GuideMessage";
import { gpsArea, modeList } from "datas";
import { gsap } from "gsap";

export let gpsCentering;

export default () => {
  const global = useContext(contextGlobal);
  const refGPS = useRef();
  const refGPS_vec = useRef();
  const refScrollAreaInner = useRef();
  const refScrollAreaInner2 = useRef();
  const [stmNearSpotId, setStmNearSpotId] = useState(null);
  const [stmViewButton, setStmViewButton] = useState(null);
  const [stmViewSpotName, setStmViewSpotName] = useState(null);
  const [stmViewButton_ar, setStmViewButton_ar] = useState(null);
  const [stmViewButton_photo, setStmViewButton_photo] = useState(null);
  const [stmViewMapScale, setStmViewMapScale] = useState(1);
  let flgScroll = false;
  let posStart = { x: 0, y: 0 };
  let posMove = { x: 0, y: 0 };
  let posScreenStart = { x: 0, y: 0 };
  let limitWidth;
  let limitHeight;
  let offsetWidth;
  let offsetHeight;
  let time;
  let init = false;
  let touchStartDistance;

  gpsCentering = handleScrollMapToGPS;

  //---useEffects
  {
    useEffect(() => {
      handleInit();
    }, []);
    useEffect(() => {
      if (!global.spots) return;
      handleGpsNearPinCheck(global.spots.val);
    }, [global.spots]);
    useEffect(() => {
      handleChangeMapScale();
    }, [stmViewMapScale]);
  }

  //---handles
  function handleInit() {
    if (refGPS.current && !init) {
      init = true;
      //gps位置設定
      gps.setWatchEvent(() => {
        handleGpsIconSet(gps.lat, gps.lon);
      });
      handleScrollMapToGPS();
      //compass方向設定
      if (compass.flgStart) {
        compass.setCustomEvent(() => {
          refGPS_vec.current.setAttribute(
            "style",
            `transform:rotate(${compass.degrees}deg)`
          );
        });
      } else {
        compass.start(() => {
          compass.setCustomEvent(() => {
            refGPS_vec.current.setAttribute(
              "style",
              `transform:rotate(${compass.degrees}deg)`
            );
          });
        });
      }
    }
  }
  function handleCalcScreenSize() {
    offsetWidth = refScrollAreaInner.current.offsetWidth;
    offsetHeight = refScrollAreaInner.current.offsetHeight;
    limitWidth = offsetWidth / 2 - window.innerWidth / 2;
    limitHeight = offsetHeight / 2 - window.innerHeight / 2;
  }
  function handleScrollMapStart(e) {
    time = Date.now(); //タッチ開始時間
    let screenX;
    let screenY;
    if (e.touches) {
      if (e.touches.length === 2) {
        flgScroll = false;
        touchStartDistance = Math.hypot(
          e.touches[0].screenX - e.touches[1].screenX,
          e.touches[0].screenY - e.touches[1].screenY
        );
      } else {
        console.log("map scroll", e.touches);
        screenX = e.touches[0].screenX;
        screenY = e.touches[0].screenY;
        flgScroll = true;
      }
    } else {
      screenX = e.screenX;
      screenY = e.screenY;
      flgScroll = true;
    }
    if (
      refScrollAreaInner.current.style.left &&
      refScrollAreaInner.current.style.top
    ) {
      const nowLeft = refScrollAreaInner.current.style.left;
      const nowTop = refScrollAreaInner.current.style.top;
      posMove.x = -1 * Number(nowLeft.replace("px", ""));
      posMove.y = -1 * Number(nowTop.replace("px", ""));
    }
    posStart.x = posMove.x + screenX;
    posStart.y = posMove.y + screenY;
    posScreenStart = { x: screenX, y: screenY };
  }
  function handleScrollMapMove(e) {
    if (e.touches && e.touches.length === 2 && touchStartDistance) {
      const distance = Math.hypot(
        e.touches[0].screenX - e.touches[1].screenX,
        e.touches[0].screenY - e.touches[1].screenY
      );
      if (distance < touchStartDistance) {
        setStmViewMapScale(2);
      }
      if (distance > touchStartDistance) {
        setStmViewMapScale(1);
      }
    }
    if (!flgScroll) return;
    let screenX;
    let screenY;
    if (e.touches) {
      screenX = e.touches[0].screenX;
      screenY = e.touches[0].screenY;
    } else {
      screenX = e.screenX;
      screenY = e.screenY;
    }
    posMove.x = posStart.x - screenX;
    posMove.y = posStart.y - screenY;
    handleScrollMap();
  }
  function handleScrollMapEnd(e) {
    if (!flgScroll) return;
    flgScroll = false;
    //慣性動作 300ms以内に操作したら追加でマップ移動調整
    if (time && time + 300 > Date.now()) {
      let screenX;
      let screenY;
      if (e.changedTouches) {
        screenX = e.changedTouches[0].screenX;
        screenY = e.changedTouches[0].screenY;
      } else {
        screenX = e.screenX;
        screenY = e.screenY;
      }
      const tmpX = posMove.x + (posScreenStart.x - screenX);
      const tmpY = posMove.y + (posScreenStart.y - screenY);
      gsap.to(posMove, {
        x: tmpX,
        y: tmpY,
        duration: 0.5,
        overwrite: true,
        onUpdate: () => {
          handleScrollMap();
        },
      });
    }
  }
  function handleScrollMap() {
    handleCalcScreenSize();
    if (offsetWidth > window.innerWidth) {
      if (Math.abs(posMove.x) > limitWidth) {
        posMove.x = Math.sign(posMove.x) * limitWidth;
      }
    } else {
      posMove.x = 0;
    }
    if (offsetHeight > window.innerHeight) {
      if (Math.abs(posMove.y) > limitHeight) {
        posMove.y = Math.sign(posMove.y) * limitHeight;
      }
    } else {
      posMove.y = 0;
    }
    refScrollAreaInner.current.setAttribute(
      "style",
      `top:${-posMove.y}px;left:${-posMove.x}px`
    );
  }
  function handleScrollMapToGPS() {
    gps.getPos(
      (coords) => {
        let lat = gps.lat;
        let lon = gps.lon;
        if (!gps.watchID) gps.watchPos();
        handleGpsIconSet(lat, lon);
        if (gps.flgLange) {
          //現在地が範囲内の処理
          handleCalcScreenSize(); //モニターサイズ取得
          const gpsPos = gps.latLonPos(gpsArea, { lat: lat, lon: lon });
          const tmpX = ((gpsPos.left - 50) / 100) * offsetWidth;
          const tmpY = ((gpsPos.top - 50) / 100) * offsetHeight;
          gsap.to(posMove, {
            x: tmpX,
            y: tmpY,
            duration: 0.5,
            overwrite: true,
            onUpdate: () => {
              handleScrollMap();
            },
          });
        } else {
          refGPS.current.classList.add(styles.off);
          // alert("現在地が範囲外です");
        }
        guideMsgUpdate();
      },
      () => {
        refGPS.current.classList.add(styles.off);
        // alert("GPS情報が取得できません");
      }
    );
  }
  function handleGpsIconSet(lat, lon) {
    const gpsPos = gps.latLonPos(gpsArea, { lat: lat, lon: lon });
    refGPS.current.setAttribute(
      "style",
      `top:${gpsPos.top}%;left:${gpsPos.left}%`
    );
    if (gps.flgLange) {
      refGPS.current.classList.remove(styles.off);
    }
  }
  function handleGpsNearPinCheck(spots) {
    //緯度(lat)：赤道を0度として、北極または南極までを90度の範囲
    //経度(lon)：本初子午線を0度として、東西へ180度
    const nearLat = 0.0001;
    const nearLon = 0.0001;
    gps.setWatchEvent(check);
    if (gps.flgDebug) gps.watchPos();
    function check() {
      let tmpNearIndex = null;
      spots.forEach((data, idx) => {
        //近いポイント判定
        if (
          Math.abs(gps.lat - data.lat) < nearLat &&
          Math.abs(gps.lon - data.lon) < nearLon
        ) {
          setStmNearSpotId(idx);
          switch (data.type) {
            case "AR":
              setStmViewButton_ar(true);
              setStmViewButton_photo(false);
              break;
            case "Photo":
              setStmViewButton_ar(false);
              setStmViewButton_photo(true);
              break;
            default:
              break;
          }
          tmpNearIndex = idx;
          setStmViewSpotName(data.name);
          setStmViewButton(true);
        }
      });
      //近いポイントがないならボタン非表示
      if (tmpNearIndex === null) {
        setStmViewButton(false);
      }
    }
  }
  function handleStartContent() {
    if (global.spots.val[stmNearSpotId].type == "AR") {
      // if (!camera.flgReady || !gps.flgReady || !compass.flgReady) {
      //   alert("機能不足によりARはご利用できません");
      //   return;
      // }
      global.mode.set(modeList[4]); //AR画面
    }
    if (global.spots.val[stmNearSpotId].type == "Photo") {
      global.mode.set(modeList[3]); //photo画面
    }
    global.spotID.set(stmNearSpotId);
  }
  function handleChangeMapScale() {
    switch (stmViewMapScale) {
      case 1:
        changeUIZoomPlus(false);
        changeUIZoomMinus(true);
        break;
      case 2:
        changeUIZoomPlus(true);
        changeUIZoomMinus(false);
        break;
      default:
        break;
    }
  }

  function handleClickPlus() {
    setStmViewMapScale(1);
  }
  function handleClickMinus() {
    setStmViewMapScale(2);
  }

  return (
    <div className={styles.map}>
      <div className={styles.uizoom}>
        <UI_Zoom
          onClickPlus={handleClickPlus}
          onClickMinus={handleClickMinus}
        />
      </div>
      <div
        className={`${styles.mapScale} ${
          stmViewMapScale === 1 && styles.scale1
        } ${stmViewMapScale === 2 && styles.scale2}`}
      >
        <div className={styles.scrollArea}>
          <div
            ref={refScrollAreaInner}
            className={styles.scrollAreaInner}
            onMouseDown={handleScrollMapStart}
            onMouseMove={handleScrollMapMove}
            onMouseUp={handleScrollMapEnd}
            onMouseLeave={handleScrollMapEnd}
            onTouchStart={handleScrollMapStart}
            onTouchMove={handleScrollMapMove}
            onTouchEnd={handleScrollMapEnd}
          >
            <div ref={refGPS} className={`${styles.gps} ${styles.off}`}>
              <div className={styles.gpsInner}>
                <img
                  ref={refGPS_vec}
                  className={styles.vec}
                  src={imgGPS}
                  alt="現在地"
                />
                <p className={`selectLan ${styles.name}`}>
                  <span className="ja">現在地</span>
                  <span className="en">current location</span>
                  <span className="ko">현재위치</span>
                  <span className="tw">目前位置</span>
                  <span className="cn">当前位置</span>
                </p>
              </div>
            </div>
            <SVG_Map />
            {global.spots.val &&
              global.spots.val.map((data, idx) => (
                <SVG_MapPin
                  key={`${idx}:${data.name}`}
                  index={idx}
                  data={data}
                  scale={stmViewMapScale}
                />
              ))}
          </div>
        </div>
        {/* ▲ scrollArea */}
      </div>
      <div className={`${styles.btm}  ${stmViewButton && styles.active}`}>
        <div className={styles.bg}>
          <div className={styles.tabSpot}>
            <div className={styles.spotName}>
              <div className="selectLan">
                <p className="ja">{stmViewSpotName && stmViewSpotName.ja}</p>
                <p className="en">{stmViewSpotName && stmViewSpotName.en}</p>
                <p className="ko">{stmViewSpotName && stmViewSpotName.ko}</p>
                <p className="tw">{stmViewSpotName && stmViewSpotName.tw}</p>
                <p className="cn">{stmViewSpotName && stmViewSpotName.cn}</p>
              </div>
            </div>
            <div className={styles.btnArea2}>
              {stmViewButton_ar && <Button_ar onClick={handleStartContent} />}
              {stmViewButton_photo && (
                <Button_photo onClick={handleStartContent} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
