/**
 * 傾きセンサー管理クラス
 */
export default class Compass {
  absolute;
  alpha;
  beta;
  gamma;
  degrees;
  os;
  direction;
  flgReady; //動作済みか
  flgStart; //処理中かどうか
  flgLandscape; //横向きになってるかどうか
  flgLandscapeL; //左横向きになってるかどうか
  flgLandscapeR; //右横向きになってるかどうか iphone8だと方位情報が正常じゃない
  customEvents;
  constructor() {
    this.startFlg = false;
    this.customEvents = [];
    if (DeviceOrientationEvent && DeviceOrientationEvent.requestPermission) {
      this.os = "iphone";
    } else if (navigator.userAgent.indexOf("Android") > 0) {
      this.os = "android";
    } else {
      this.os = "pc";
    }
  }
  /**
   * 傾きセンサーイベント開始
   * @param {function} successEvent 第1引数 センサー開始時実行する関数
   * @param {function} errorEvent 第2引数 iphone・androidでない時実行する関数
   */
  start() {
    const root = this;
    let successEvent;
    let errorEvent;
    if (typeof arguments[0] == "function") {
      successEvent = arguments[0];
    }
    if (typeof arguments[1] == "function") {
      errorEvent = arguments[1];
    }
    //iphone用 傾きセンサー許可処理
    if (this.os === "iphone") {
      DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === "granted") {
            console.log("iphone DeviceOrientation API OK");
            window.addEventListener("deviceorientation", eventOrientation);
          }
        })
        .catch(() => {
          if (errorEvent) errorEvent();
        });
    } else if (this.os === "android") {
      window.addEventListener("deviceorientationabsolute", eventOrientation);
    } else {
      if (errorEvent) errorEvent();
    }
    function eventOrientation(event) {
      if (!root.flgStart) {
        root.flgStart = true;
        if (successEvent) successEvent();
      }
      root.flgReady = true;
      root.absolute = event.absolute;
      root.alpha = event.alpha;
      root.beta = event.beta;
      root.gamma = event.gamma;
      root.flgLandscapeL = false;
      root.flgLandscapeR = false;
      root.flgLandscape = false;

      //左横持ち時
      if (
        !(root.beta > 30 && root.beta < 90) &&
        ((root.gamma > -90 && root.gamma < 0) ||
          (root.gamma > 70 && root.gamma < 90))
      ) {
        root.flgLandscapeL = true;
        root.flgLandscape = true;
      }
      //右横持ち時
      if (
        !(root.beta > 30 && root.beta < 90) &&
        ((root.gamma < 90 && root.gamma > 0) ||
          (root.gamma > -90 && root.gamma < -70))
      ) {
        root.flgLandscapeR = true;
        root.flgLandscape = true;
      }

      if (root.os === "iphone") {
        root.degrees = event.webkitCompassHeading;
      } else {
        root.degrees = compassHeading(root.alpha, root.beta, root.gamma);
      }

      if (document && document.querySelector("#debug")) {
        document.querySelector("#debug").innerHTML =
          root.absolute +
          "<br>" +
          root.alpha +
          "<br>" +
          root.beta +
          "<br>" +
          root.gamma +
          "<br>os: " +
          root.os +
          "<br>landscape: " +
          root.flgLandscape +
          "<br>degrees: " +
          root.degrees;
      }
      if (
        (root.degrees > 337.5 && root.degrees < 360) ||
        (root.degrees > 0 && root.degrees < 22.5)
      ) {
        root.direction = "north";
      } else if (root.degrees > 22.5 && root.degrees < 67.5) {
        root.direction = "north east";
      } else if (root.degrees > 67.5 && root.degrees < 112.5) {
        root.direction = "east";
      } else if (root.degrees > 112.5 && root.degrees < 157.5) {
        root.direction = "south east";
      } else if (root.degrees > 157.5 && root.degrees < 202.5) {
        root.direction = "south";
      } else if (root.degrees > 202.5 && root.degrees < 247.5) {
        root.direction = "south west";
      } else if (root.degrees > 247.5 && root.degrees < 292.5) {
        root.direction = "west";
      } else if (root.degrees > 292.5 && root.degrees < 337.5) {
        root.direction = "north west";
      }
      if (root.customEvents.length) {
        root.customEvents.forEach((event) => {
          event();
        });
      }
    }
    function compassHeading(alpha, beta, gamma) {
      var degtorad = Math.PI / 180;
      var _x = beta ? beta * degtorad : 0;
      var _y = gamma ? gamma * degtorad : 0;
      var _z = alpha ? alpha * degtorad : 0;
      var cX = Math.cos(_x);
      var cY = Math.cos(_y);
      var cZ = Math.cos(_z);
      var sX = Math.sin(_x);
      var sY = Math.sin(_y);
      var sZ = Math.sin(_z);
      var Vx = -cZ * sY - sZ * sX * cY;
      var Vy = -sZ * sY + cZ * sX * cY;
      var compassHeading = Math.atan(Vx / Vy);
      if (Vy < 0) {
        compassHeading += Math.PI;
      } else if (Vx < 0) {
        compassHeading += 2 * Math.PI;
      }
      return compassHeading * (180 / Math.PI); // Compass Heading (in degrees)
    }
  }
  /**
   * 傾きセンサー実行中イベント
   * @param {function} customEvent センサーイベント内で実行する関数
   */
  setCustomEvent(customEvent) {
    const root = this;
    if (typeof customEvent == "function") {
      root.customEvents.push(customEvent);
    }
  }
}
