/**
 * GPS管理クラス
 */
export default class GPS {
  coords;
  lat;
  lon;
  watchID; //ID有なら処理中
  lange; //経度緯度範囲
  flgEnable; //GPS機能有無
  flgReady; //動作済みか
  flgLange; //現在地が範囲内ならtrue  latLonPosで更新
  flgDebug; //現在位置を取得せず仮のデータで動作させる 位置はコンストラクタで設定
  watchEvents; //後から追加処理を設定できる関数配列
  constructor() {
    if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
      this.flgEnable = true;
    } else {
      this.flgEnable = false;
      return;
    }
    if (arguments[0]) {
      this.lange = arguments[0];
    }
    if (arguments[1]) {
      this.flgDebug = true;
      this.lat = arguments[1].lat;
      this.lon = arguments[1].lon;
    } else {
      this.flgDebug = false;
    }
    this.watchEvents = [];
    this.flgReady = false;
    this.flgLange = false;
  }
  /**
   * @description 経度緯度取得
   * @param {function} successEvent 第1引数 成功時実行する関数
   * @param {function} errorEvent 第2引数 失敗時時実行する関数
   */
  getPos() {
    let successEvent;
    let errorEvent;
    if (typeof arguments[0] == "function") {
      successEvent = arguments[0];
    }
    if (typeof arguments[1] == "function") {
      errorEvent = arguments[1];
    }
    if (!this.flgEnable) {
      if (errorEvent) errorEvent();
      return;
    }
    if (this.flgDebug) {
      if (successEvent) successEvent();
      this.flgReady = true;
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.flgReady = true;
        this.coords = position.coords;
        this.lat = position.coords.latitude;
        this.lon = position.coords.longitude;
        if (successEvent) successEvent(this.coords);
        if (this.lange) {
          this.latLonPos(this.lange, { lat: this.lat, lon: this.lon });
        }
      },
      () => {
        if (errorEvent) errorEvent();
      },
      { timeout: 3000, enableHighAccuracy: true }
    );
  }
  /**
   * @description 経度緯度継続取得
   * @param {function} successEvent 第1引数 成功時実行する関数
   * @param {function} errorEvent 第2引数 失敗時時実行する関数
   */
  watchPos() {
    let successEvent;
    let errorEvent;
    if (typeof arguments[0] == "function") {
      successEvent = arguments[0];
    }
    if (typeof arguments[1] == "function") {
      errorEvent = arguments[1];
    }
    if (!this.flgEnable) {
      if (errorEvent) errorEvent();
      return;
    }
    if (this.flgDebug) {
      if (successEvent) successEvent();
      if (this.watchEvents.length) {
        this.watchEvents.forEach((event) => {
          event();
        });
      }
      return;
    }
    this.clearWatch();
    this.watchID = navigator.geolocation.watchPosition(
      (position) => {
        this.flgReady = true;
        this.coords = position.coords;
        this.lat = position.coords.latitude;
        this.lon = position.coords.longitude;
        if (successEvent) successEvent();
        if (this.lange) {
          this.latLonPos(this.lange, { lat: this.lat, lon: this.lon });
        }
        if (this.watchEvents.length) {
          this.watchEvents.forEach((event) => {
            event();
          });
        }
      },
      () => {
        if (errorEvent) errorEvent();
      },
      { timeout: 3000, enableHighAccuracy: true }
    );
  }
  /**
   * @description 経度緯度継続取得中止
   */
  clearWatch() {
    if (this.watchID) navigator.geolocation.clearWatch(this.watchID);
    this.watchID = null;
  }
  /**
   * @description 経度緯度範囲のどの位置か top left %で返す(CSS設定用)
   * @param {json} lange { top:number, bottom:number, left:number, right:number }
   * @param {json} currentPos { lat:number, lon:number }
   * @return {json} {top:number, left:number}
   */
  latLonPos(lange, currentPos) {
    const top =
      ((-1 * (currentPos.lat - lange.top)) / (lange.top - lange.bottom)) * 100;
    const left =
      ((-1 * (lange.left - currentPos.lon)) / (lange.right - lange.left)) * 100;
    if (top > 100 || top < 0 || left > 100 || left < 0) {
      this.flgLange = false;
    } else {
      this.flgLange = true;
    }
    return { top: top, left: left };
  }
  setWatchEvent(customEvent) {
    if (typeof customEvent == "function") {
      this.watchEvents.push(customEvent);
    }
  }
}
