import { useEffect, useRef, useContext } from "react";
import styles from "./styles.module.css";
import { gps, compass, contextGlobal } from "App";
import imgGPS from "./images/vector_gps_direction.svg";
import Button_Close from "components/Button_Close";
import SVG_MapPinMini from "components/SVG_MapPinMini";
import { modeList, gpsArea } from "datas";

export default () => {
  const global = useContext(contextGlobal);
  const refGPS = useRef(null);
  const refGPS_vec = useRef(null);
  let init = false;

  useEffect(() => {
    if (refGPS.current && !init) {
      init = true;
      //gps位置設定
      gps.setWatchEvent(() => {
        gpsIconSet(gps.lat, gps.lon);
      });
      gps.getPos(
        () => {
          let lat = gps.lat;
          let lon = gps.lon;
          if (!gps.watchID) gps.watchPos();
          gpsIconSet(lat, lon);
        },
        () => {
          refGPS.current.classList.add(styles.off);
        }
      );
      //compass方向設定
      compass.setCustomEvent(() => {
        refGPS_vec.current.setAttribute(
          "style",
          `transform:rotate(${compass.degrees}deg)`
        );
      });
    }
  }, []);

  function gpsIconSet(lat, lon) {
    refGPS.current.classList.remove(styles.off);
    const gpsPos = gps.latLonPos(gpsArea, { lat: lat, lon: lon });
    refGPS.current.setAttribute(
      "style",
      `top:${gpsPos.top}%;left:${gpsPos.left}%`
    );
  }

  function handleClose() {
    global.mode.set(modeList[1]);
  }
  return (
    <div
      className={`${styles.screen} ${
        global.mode.val == modeList[2] ? styles.active : null
      }`}
    >
      <div className={styles.inner}>
        <div className={styles.btnArea}>
          <Button_Close onClick={handleClose} />
        </div>
        <div>
          <div className={styles.map}>
            <div ref={refGPS} className={`${styles.gps} ${styles.off}`}>
              <div className={styles.gpsInner}>
                <img
                  ref={refGPS_vec}
                  className={styles.vec}
                  src={imgGPS}
                  alt="現在地"
                />
              </div>
            </div>
            {global.spots.val &&
              global.spots.val.map((data, idx) => (
                <SVG_MapPinMini key={`${data.name} ${idx}`} data={data} />
              ))}
            <img src="./images/map_out.svg" alt="マップイメージ" />
          </div>
        </div>
      </div>
    </div>
  );
};
