/**
 * スマホスリープ時間管理クラス
 */
export default class WakeLock {
  obj;
  flgEnable; //機能有無
  flgStart; //スリープロック設定かどうか
  constructor() {
    if (navigator.wakeLock) {
      this.flgEnable = true;
    }
  }
  /**
   * @description スリープロック開始
   * @param {function} successEvent 第1引数 成功時実行する関数
   * @param {function} errorEvent 第2引数 失敗時時実行する関数
   */
  async start() {
    try {
      this.obj = await navigator.wakeLock.request("screen");
      this.flgStart = true;
      if (typeof arguments[0] == "function") {
        arguments[0]();
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
      if (typeof arguments[1] == "function") {
        arguments[1](err);
      }
    }
  }
  /**
   * @description スリープロック停止
   */
  stop() {
    if (!this.obj) return;
    this.obj.release();
    this.flgStart = false;
  }
}
