export const vertexShader1 = `
                varying vec2 vUv;
                void main() {
                vUv = uv;
                vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
                vec4 mvPosition =  viewMatrix * worldPosition;
                gl_Position = projectionMatrix * mvPosition;
                }`;

export const fragmentShader1 = `
                varying vec2 vUv;
                uniform float opacity;
                uniform float fade;
                uniform sampler2D tFade;
                uniform sampler2D tPhoto;
                void main() {
                vec4 tFadeColor = texture2D( tFade, vUv );
                vec4 tPhotoColor = texture2D( tPhoto, vUv );
                gl_FragColor = vec4(tPhotoColor.rgb, smoothstep(0.0, 1.0, tFadeColor.r + fade) * opacity * tPhotoColor.a );
                }`;

export const fragmentShader2 = `
                varying vec2 vUv;
                uniform float fade;
                uniform sampler2D tFade;
                uniform vec3 bgColor;
                void main() {
                vec4 tFadeColor = texture2D( tFade, vUv );
                gl_FragColor = vec4(bgColor.rgb, smoothstep(0.0, 1.0, tFadeColor.r + fade) );
                }`;
