//【modeステートメントの状態一覧】
// 0 : "start"  ： 開始画面
// 1 : "map"    ： マップ画面
// 2 : "mapAll" ： マップ全体画面
// 3 : "photo" ： 写真表示画面
// 4 : "ar"     ： AR画面
export const modeList = ["start", "map", "mapAll", "detail", "ar"];

//【GPSの上下左右値】
export const gpsArea = {
  top: 34.68782,
  bottom: 34.678093,
  left: 135.182543,
  right: 135.197131,
};
