/**
 * カメラ管理クラス
 */
export default class Camera {
  /**
   * カメラ機能有無真偽値
   * @type {boolean}
   */
  flgEnable;
  /**
   * 一回以上動作済みか真偽値
   * @type {boolean}
   */
  flgReady;
  /**
   * 処理中か真偽値
   * @type {boolean}
   */
  flgStart;
  /**
   * カメラ停止用真偽値
   * @type {boolean}
   */
  flgStop;
  /**
   * 後から追加処理を設定できる関数配列
   * @type {array<function>}
   */
  customEvents;
  /**
   * カメラから取得した映像データ
   * @type {MediaStream}
   */
  stream;
  /**
   * 出力先のVideo
   * @type {HTMLVideoElement}
   */
  video;
  /**
   * Videoの設定値
   * @type {json}
   */
  videoData;
  /**
   * 利用できるカメラID(ID指定して映像出力したい時)
   * @type {array}
   */
  deviceId;

  constructor() {
    if (arguments[0]) {
    }
    this.customEvents = [];
    if (
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia &&
      navigator.mediaDevices.enumerateDevices
    ) {
      this.flgEnable = true;
    }
    this.deviceId_videoInput = "";
    //video初期値設定 videoの「deviceId」値をカメラID指定も可能
    //スマホの場合「facingMode」で指定が無難
    //背面カメラ：environment / 前カメラ：user
    this.videoData = {
      audio: false,
      video: {
        facingMode: "environment",
        width: { min: 1280, max: 1920 },
        height: { min: 720, max: 1080 },
      },
    };
  }

  /**
   * @description カメラから映像取得
   * @param {function} successEvent 第1引数 成功時実行する関数
   * @param {function} errorEvent 第2引数 失敗時時実行する関数
   */
  getUserMediaCamera() {
    const root = this;
    let successEvent;
    let errorEvent;
    if (typeof arguments[0] == "function") {
      successEvent = arguments[0];
    }
    if (typeof arguments[1] == "function") {
      errorEvent = arguments[1];
    }
    if (!root.flgEnable) {
      if (errorEvent) errorEvent("カメラ機能に対応していません");
      return;
    }

    this.stop(); //既に実行していた場合、停止する

    navigator.mediaDevices
      .getUserMedia(root.videoData)
      .then(function (stream) {
        root.stream = stream;
        root.flgReady = true;
        if (!root.flgStart) {
          root.flgStart = true;
          if (successEvent) successEvent(stream);
        }
        if (root.video) {
          root.video.srcObject = stream;
          root.video.play();
        }
        if (root.customEvents.length) {
          root.customEvents.forEach((event) => {
            event(stream);
          });
        }
      })
      .catch((e) => {
        if (errorEvent) errorEvent(e);
      });
  }

  /**
   * カメラ実行中 処理する関数追加
   * @param {function} customEvent
   */
  setCustomEvent(customEvent) {
    if (typeof customEvent == "function") {
      this.customEvents.push(customEvent);
    }
  }

  /**
   * カメラ映像出力先設定
   * @param {HTMLVideoElement} video
   */
  async setVideo(video) {
    const root = this;
    this.video = video;

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        //デバイスのビデオ入力IDを一応取得（スマホならフロントとリアカメラのID）
        root.deviceId = devices.filter(
          (device) => device.kind === "videoinput"
        );
        return true;
      })
      .catch(function (error) {
        console.log(error);
        throw new Error(error);
      });
    //ビデオをアスペクト比維持してセンタリング調整
    video.addEventListener("loadedmetadata", adjustVideo);
    window.addEventListener("resize", adjustVideo);
    function adjustVideo() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      let videoAspect = videoWidth / videoHeight;
      let windowAspect = windowWidth / windowHeight;
      if (windowAspect < videoAspect) {
        let newWidth = videoAspect * windowHeight;
        video.style.width = newWidth + "px";
        video.style.marginLeft = -(newWidth - windowWidth) / 2 + "px";
        video.style.height = windowHeight + "px";
        video.style.marginTop = "0px";
      } else {
        let newHeight = 1 / (videoAspect / windowWidth);
        video.style.height = newHeight + "px";
        video.style.marginTop = -(newHeight - windowHeight) / 2 + "px";
        video.style.width = windowWidth + "px";
        video.style.marginLeft = "0px";
      }
    }
  }

  /**  カメラ停止 */
  stop() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.flgStart = false;
    }
  }
}
