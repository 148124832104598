import { useEffect, useState, useContext, useRef } from "react";
import styles from "./styles.module.css";
import imgEx from "./images/vector_ex.svg";
import { modeList } from "datas";
import { camera, gps, compass, contextGlobal } from "App";

export let guideMsgUpdate;

export default () => {
  const global = useContext(contextGlobal);
  const refMsg = useRef("");
  const [active, setActive] = useState(false);
  guideMsgUpdate = handleUpdate;

  // useEffects
  {
    useEffect(() => {
      handleUpdate();
    }, [global.mode.val, global.landscape.val]);
  }

  function handleUpdate() {
    let permission = false;
    if (camera.flgReady && gps.flgReady && compass.flgStart) {
      permission = true;
    }
    switch (global.mode.val) {
      case modeList[1]:
        if (permission && gps.flgLange) {
          handleUpdateMsg(
            `<div class="selectLan">
            <p class="ja">各ピンをタップで写真閲覧、赤いピンに近づくと「ARスタート」ボタンが画面下に表示されます</p>
            <p class="en">Tap each pin to view photos. When you approach a red pin, an "AR Start" button will appear at the bottom of the screen</p>
            <p class="ko">각 핀을 탭하면 사진을 볼 수 있습니다. 빨간 핀에 가까워지면 화면 하단에 "AR 시작" 버튼이 표시됩니다</p>
            <p class="tw">各個點擊圖釘即可查看照片,接近紅色圖釘時,畫面下方會出現「AR開始」按鈕</p>
            <p class="cn">点击每个图钉即可查看照片,靠近红色图钉时,屏幕下方会显示“AR启动”按钮</p>
            </div>`
          );
        } else {
          handleUpdateMsg(
            `<div class="selectLan">
            <p class="ja">各ピンをタップで写真閲覧、条件不足のためAR表示はご利用できません</p>
            <p class="en">Tap each pin to view photos. AR display is not available due to insufficient conditions</p>
            <p class="ko">각 핀을 탭하여 사진을 볼 수 있습니다. 조건 부족으로 인해 AR 표시를 사용할 수 없습니다</p>
            <p class="tw">點擊各個圖釘即可查看照片,由於條件不足,無法使用AR顯示功能</p>
            <p class="cn">点击每个图钉即可查看照片,由于条件不足,无法使用AR显示功能</p>
            </div>`
          );
        }
        break;
      case modeList[2]:
        handleUpdateMsg(
          `<div class="selectLan">
            <p class="ja">利用可能エリア マップ全体です</p>
            <p class="en">This is the full map of the available area</p>
            <p class="ko">이것은 전체 이용 가능 지역 지도입니다</p>
            <p class="tw">這是整個可用區域地圖</p>
            <p class="cn">这是整个可用区域的地图</p>
            </div>`
        );
        break;
      case modeList[3]:
        handleUpdateMsg(
          `<div class="selectLan">
            <p class="ja">震災時の写真と詳細情報です</p>
            <p class="en">These are photos and detailed information from the earthquake</p>
            <p class="ko">이것은 지진 당시의 사진과 자세한 정보입니다</p>
            <p class="tw">這是震災時的照片和詳細資訊</p>
            <p class="cn">这是震灾时的照片和详细信息</p>
            </div>`
        );
        break;
      case modeList[4]:
        let msgJA = "フレーム箇所でAR表示されます";
        let msgEN = "It will be displayed in AR at the frame location.";
        let msgKO = "프레임 위치에서 AR이 표시됩니다.";
        let msgTW = "在框架位置會顯示AR";
        let msgCN = "在框架位置会显示AR";
        if (compass.os === "iphone" && global.landscape.val) {
          msgJA += " (iphoneは縦持ちでご利用ください)";
          msgEN += " (Please use the iPhone in portrait mode)";
          msgKO += " (iPhone은 세로 모드로 사용해 주세요)";
          msgTW += " (請將iPhone保持直立使用)";
          msgCN += " (请将iPhone保持竖屏使用)";
        }
        handleUpdateMsg(`<div class="selectLan">
            <p class="ja">${msgJA}</p>
            <p class="en">${msgEN}</p>
            <p class="ko">${msgKO}</p>
            <p class="tw">${msgTW}</p>
            <p class="cn">${msgCN}</p>
            </div>`);
        break;
      default:
        break;
    }
  }

  function handleUpdateMsg(text) {
    setActive(false);
    setTimeout(() => {
      setActive(true);
      refMsg.current.innerHTML = text;
    }, 500);
  }
  return (
    <div className={`${styles.guide} ${active ? styles.active : null}`}>
      <div className={styles.inner}>
        <div className={styles.icon}>
          <img src={imgEx} alt="ガイドアイコン" />
        </div>
        <div ref={refMsg}></div>
      </div>
    </div>
  );
};
