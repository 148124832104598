import { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import imgIcon from "./images/vector_pin.svg";

export default (props) => {
  const refButton = useRef(null);
  useEffect(() => {}, []);

  function handleClick() {
    if (props.onClick) props.onClick();
  }

  return (
    <a ref={refButton} onClick={handleClick} className={styles.button}>
      <div className={styles.inner}>
        <span className={styles.icon}>
          <img src={imgIcon} alt="icon" />
        </span>
        <span>{props.text ? props.text : "デバッグ"}</span>
      </div>
    </a>
  );
};
