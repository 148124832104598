import { useState } from "react";
import styles from "./styles.module.css";
import imgPin from "./images/vector_pin.svg";

export default (props) => {
  return (
    <a onClick={props.onClick} className={`${styles.btn} ${styles.ar}`}>
      <span className={styles.icon}>
        <img src={imgPin} alt="ピンアイコン" />
      </span>
      <span>ARスタート</span>
    </a>
  );
};
